/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  FirmwareVersion: any;
};

export type AndroidFirmware = {
  __typename?: 'AndroidFirmware';
  version: Scalars['FirmwareVersion'];
};

export type FirmwareReleaseInput = {
  /** The URL which contains the uploaded firmware. */
  firmwareUrl: Scalars['String'];
  /** The firmware version of this release. */
  firmwareVersion: Scalars['String'];
  /** Visibility of the firmware. */
  isVisible?: InputMaybe<Scalars['Boolean']>;
  /** The platform to which the firmware belongs. */
  platformId: Scalars['ID'];
  /** Pre required firmware version. */
  preRequired?: InputMaybe<Scalars['String']>;
  /** The release notes of this release. */
  releaseNotes?: InputMaybe<Scalars['String']>;
  /** Scalar firmware version. */
  scalarVersion?: InputMaybe<Scalars['String']>;
};

export type FirmwareReleasePayload = {
  __typename?: 'FirmwareReleasePayload';
  /** The platform for which a firmware was released. */
  platform: Platform;
};

export type FirmwareReleases = {
  __typename?: 'FirmwareReleases';
  android: Array<AndroidFirmware>;
};

export type FirmwareUploadRequestInput = {
  /** The Base64-encoded MD5 hash of this release. */
  checksum: Scalars['String'];
  /** The firmware version of this release. */
  firmwareVersion: Scalars['String'];
  /** The platform to which the firmware belongs. */
  platformId: Scalars['ID'];
};

export type FirmwareUploadRequestPayload = {
  __typename?: 'FirmwareUploadRequestPayload';
  /** The URL to fetch the firmware. */
  firmwareUrl: Scalars['String'];
  /** The signed URL to upload the firmware. */
  uploadUrl: Scalars['String'];
};

export type LatestFirmware = {
  __typename?: 'LatestFirmware';
  android: AndroidFirmware;
};

export type Mutation = {
  __typename?: 'Mutation';
  firmwareRelease: FirmwareReleasePayload;
  firmwareUploadRequest: FirmwareUploadRequestPayload;
  /** Connects an organization up to the ProStore. */
  organizationConnectToProStore: Organization;
  /** Creates a new organization and invites the first owner user */
  organizationCreate: Organization;
  /** Delete organization */
  organizationDelete: Organization;
  /** Toggles developer access for organizations connected to Prostore. */
  organizationToggleProstoreDeveloperAccess: Organization;
  /** Update organization */
  organizationUpdate: Organization;
  /** Creates an invitation email and sends it to the target user. */
  sendInvitation: Scalars['Boolean'];
};


export type MutationFirmwareReleaseArgs = {
  input: FirmwareReleaseInput;
};


export type MutationFirmwareUploadRequestArgs = {
  input: FirmwareUploadRequestInput;
};


export type MutationOrganizationConnectToProStoreArgs = {
  input: OrganizationConnectToProStoreInput;
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput;
};


export type MutationOrganizationDeleteArgs = {
  input: OrganizationDeleteInput;
};


export type MutationOrganizationToggleProstoreDeveloperAccessArgs = {
  input: OrganizationToggleProstoreDeveloperAccessInput;
};


export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationSendInvitationArgs = {
  input: SendInvitationInput;
};

export type Organization = {
  __typename?: 'Organization';
  /** The country of the organization */
  countryCode?: Maybe<Scalars['String']>;
  /** The date when the organization was created. */
  createdAt: Scalars['DateTime'];
  /** The number of displays claimed by the organization. */
  displayCount: Scalars['Int'];
  /** A human- and URL-friendly identifier of the organization. */
  handle: Scalars['String'];
  /** The identifier of the organization. */
  id: Scalars['ID'];
  /** The name of the organization. */
  name: Scalars['String'];
  owner?: Maybe<OwnerUser>;
  /** The organization's connection with the ProStore. */
  proStore?: Maybe<ProStoreConnection>;
};

export type OrganizationConnectToProStoreInput = {
  /** The identifier of the organization. */
  organizationId: Scalars['String'];
};

export type OrganizationCreateInput = {
  /** The country of the organization. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** A human- and URL-friendly identifier of the organization. */
  handle: Scalars['String'];
  /** The name of the organization. */
  name: Scalars['String'];
  /** The email address of the first "admin" user. An invitation e-mail will be sent to this address. */
  ownerEmail: Scalars['String'];
  /** The last name of the organization's owner. */
  ownerFamilyName: Scalars['String'];
  /** The first name of the organization's owner. */
  ownerGivenName: Scalars['String'];
};

export type OrganizationDeleteInput = {
  /** The identifier of the organization. */
  organizationId: Scalars['String'];
};

export type OrganizationToggleProstoreDeveloperAccessInput = {
  /** Whether developer access should be enabled or disabled */
  enabled: Scalars['Boolean'];
  /** The identifier of the organization. */
  organizationId: Scalars['String'];
};

export type OrganizationUpdateInput = {
  /** The country of the organization. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** A human- and URL-friendly identifier of the organization. */
  handle: Scalars['String'];
  /** The name of the organization. */
  name: Scalars['String'];
  /** The identifier of the organization. */
  organizationId: Scalars['String'];
};

export type OwnerUser = {
  __typename?: 'OwnerUser';
  /** The email of the user. */
  email: Scalars['String'];
  /** The identifier of the user. */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** The identifier of organization user is part of */
  organizationId: Scalars['String'];
};

export type Platform = {
  __typename?: 'Platform';
  description?: Maybe<Scalars['String']>;
  firmwareReleases: FirmwareReleases;
  id: Scalars['ID'];
  latestFirmwareRelease?: Maybe<LatestFirmware>;
  name: Scalars['String'];
  type: PlatformType;
  version?: Maybe<Scalars['String']>;
};

export enum PlatformType {
  ProTv = 'ProTV',
  Signage = 'Signage'
}

export type ProStoreConnection = {
  __typename?: 'ProStoreConnection';
  /** The date on which the ProStore connection was activated. */
  createdAt: Scalars['DateTime'];
  /** Whether or not developer access is enabled. */
  developerAccess: Scalars['Boolean'];
  /** The unique identifier of the ProStore connection. */
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** The organization of the current user. */
  organizations: Array<Organization>;
  platforms: Array<Platform>;
};

export type SendInvitationInput = {
  /** The email of the invitee. */
  inviteeEmail: Scalars['String'];
};
