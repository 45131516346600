import { gql } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon, RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TableInstance } from 'react-table';
import {
  OrganizationRowFragment,
  useSendInvitationMutation,
} from './__generated__/OrganizationsTable.graphql';

interface Props {
  table: TableInstance<OrganizationRowFragment>;
}

export type RowData = {
  id: string;
  name: string;
  handle: string;
  onboardedAdmin: string;
  lastLogin: Date | null;
};

export function OrganizationsTable({ table }: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = table;
  const [sendInvitation] = useSendInvitationMutation();
  const toast = useToast();

  const resendInvitation = async (data: RowData) => {
    const inviteeEmail = data.onboardedAdmin;
    try {
      const response = await sendInvitation({
        variables: {
          input: {
            inviteeEmail,
          },
        },
      });

      const result = response.data?.sendInvitation ?? false;
      if (result) {
        toast({
          status: 'success',
          title: 'Invitation sent',
          description: 'The invitation has been sent to the user',
        });
      } else {
        toast({
          status: 'error',
          title: 'Invitation not sent',
          description: 'The invitation could not be sent to the user',
        });
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Failed to send the invitation',
        description: 'Something unexpected has happened',
      });
    }
  };

  return (
    <Table {...getTableProps()} variant="simple">
      <Thead pt="2" pb="4" w="full">
        {headerGroups.map((headerGroup) => {
          const headerGroupProps = headerGroup.getHeaderGroupProps();
          return (
            <Tr {...headerGroupProps} key={headerGroupProps.key}>
              {headerGroup.headers.map((column) => {
                const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                return (
                  <Th
                    {...headerProps}
                    key={headerProps.key}
                    color={column.isSorted ? 'gray.800' : 'gray.400'}
                    _hover={
                      column.disableSortBy
                        ? {}
                        : {
                            textColor: 'gray.800',
                          }
                    }
                    onClick={(e) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (headerProps as any).onClick?.(e);
                    }}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box flex="1">{column.render('Header') as ReactNode}</Box>
                      {column.canSort && (
                        <Box>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ChevronDownIcon display="inherit" />
                            ) : (
                              <ChevronUpIcon display="inherit" />
                            )
                          ) : (
                            <ChevronDownIcon visibility="hidden" />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          );
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          const rowProps = row.getRowProps();
          return (
            <Tr {...rowProps} key={rowProps.key}>
              {row.cells.map((cell) => {
                const cellProps = cell.getCellProps();

                return (
                  <Td {...cellProps} key={cellProps.key}>
                    {cell.column.Header?.toString() === 'Last login' && cell.value === null ? (
                      <HStack>
                        <Box>{cell.render('Cell') as ReactNode}</Box>
                        <Tooltip label="Resend invitation email">
                          <Button
                            variant={'outline'}
                            height={6}
                            width={2}
                            marginLeft={2}
                            onClick={() => resendInvitation(cell.row.values as RowData)}
                          >
                            <RepeatIcon color={'blue.700'} />
                          </Button>
                        </Tooltip>
                      </HStack>
                    ) : (
                      (cell.render('Cell') as ReactNode)
                    )}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

OrganizationsTable.graphql = {
  fragment: gql`
    fragment OrganizationRow on Organization {
      id
      name
      handle
      createdAt
      displayCount
      countryCode
      owner {
        id
        email
        lastLogin
      }
      ...ProStoreConnection_organization
    }
  `,
  mutation: gql`
    mutation SendInvitation($input: SendInvitationInput!) {
      sendInvitation(input: $input)
    }
  `,
};
